import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo}  alt="logo" />
        <p>
          COMING SOON!
        </p>
    

      </header>
    </div>
  );
}

export default App;
